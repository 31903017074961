import React from "react"

const Card = ({ title, image, className, imageClassName }: any) => {
  return (
    <div
      className={`card  p-5 md:p-10 ${className} cursor-pointer transition-all hover:scale-95`}
    >
      <div
        className={`${imageClassName} mask mask-hexagon flex h-32 max-h-52 w-full shrink-0 items-center justify-center `}
      >
        {image}
      </div>
      <div className="card-body items-center text-center">
        <h2 className="card-title text-neutral-content">{title}</h2>
      </div>
    </div>
  )
}

export default Card
