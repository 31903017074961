import * as React from "react"
import LastEvents from "../components/Events/LastEvents"
import UpcomingEvents from "../components/Events/FutureEvents"
import { Layout } from "../components/Layout/Layout"
import QuickLinks from "../components/QucikLinks/QuickLinks"

// markup
const IndexPage = () => {
  return (
    <>
      <Layout activePage={{ title: "", slug: "/" }}>
        <div className="content">
          <QuickLinks />
          <div className="grid gap-5 md:grid-cols-2 md:gap-10">
            <LastEvents />
            <UpcomingEvents />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
