import React from "react"
import useFutureEventsQuery from "../../graphql/useFutureEventsQuery"
import { parseDate } from "../../utils/parseDate"

const FutureEvents = () => {
  const futureEvents = useFutureEventsQuery()
  console.log(futureEvents)

  return (
    <div>
      <div className="card prose bg-base-300 p-10">
        <h1>Plánované akce</h1>
        {futureEvents &&
          futureEvents.map((event, index) => {
            return (
              <ul
                className="ml-2 list-none  border-l-4 border-primary pl-10"
                key={`upcoming-event-${index}`}
              >
                <li>
                  <span className="-ml-16 flex items-center  ">
                    <div className="mx-4 h-1 w-10 shrink-0 bg-primary"></div>
                    <h3>{event.title}</h3>
                  </span>
                  <span className="badge badge-primary ml-2 p-2 font-medium">
                    {parseDate(event.from, event.to)}
                  </span>
                </li>
              </ul>
            )
          })}
      </div>
    </div>
  )
}

export default FutureEvents
