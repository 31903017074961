import React from "react"
import Card from "./Card"

import Image1 from "../../images/Brainstormingsession_Outline.svg"
import Image2 from "../../images/Time_Outline.svg"
import Image3 from "../../images/Winners_Outline.svg"

const QuickLinks = () => {
  return (
    <div className="">
      <div className="prose">
        <h1>Rychlé odkazy</h1>
      </div>
      <div className="my-8 grid grid-cols-2 gap-5 md:grid-cols-4 md:gap-10">
        <Card
          image={<Image2 />}
          title="Jídelna"
          className="bg-primary text-base-300"
          imageClassName="bg-base-300"
        />
        <Card
          image={<Image1 />}
          title="Rozvrhy"
          className="bg-secondary text-base-300"
          imageClassName="bg-base-300"
        />
        <Card
          image={<Image3 />}
          title="Aktuality"
          className="bg-accent text-base-300"
          imageClassName="bg-base-300"
        />
        <Card
          image={
            <div className="p-10">
              <img
                className="w-full  "
                src="https://soupdy.cz/wp-content/uploads/2016/06/bakalari-nova-tvar-02.png"
              />
            </div>
          }
          title="Bakaláři"
          className="bg-neutral text-base-300"
          imageClassName="bg-base-300"
        />
      </div>
    </div>
  )
}

export default QuickLinks
