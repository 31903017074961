import { graphql, useStaticQuery } from "gatsby"
import { StrapiEvent } from "../interfaces/Event"
import { NavigationTreeItem } from "../interfaces/Navigations"

const useFutureEventsQuery = () => {
  const futureEventsQuery = useStaticQuery(graphql`
    query FutureEventsQuery {
      allStrapiFutureEvent(sort: { fields: from, order: ASC }) {
        nodes {
          ...STRAPI_FUTURE_EVENTFragment
        }
      }
    }
  `)
  const result: StrapiEvent[] = futureEventsQuery?.allStrapiFutureEvent?.nodes

  return result
}

export default useFutureEventsQuery
